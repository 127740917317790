import { useEffect } from 'react'
import { getPluralForm } from '../../lib'
import { getPaymentData, useFreekassaData, useGMPaysData, usePGSData } from '../../model/donate'
import { usePayForm } from '../../model/pay'
import { useStep } from '../../model/step'

export const FormValidate = () => {
  const { prevStep } = useStep()
  const { username, server, premiumAmount, premiumId } = usePayForm()

  const { data: pgsData, loading: pgsLoading, fail: pgsFail } = usePGSData()
  const { data: freekassaData, loading: freekassaLoading, fail: freekassaFail } = useFreekassaData()
  const { data: gmpaysData, loading: gmpaysLoading, fail: gmpaysFail } = useGMPaysData()

  useEffect(() => {
    getPaymentData()
  }, [])

  return (
    <div className="donateform__finish">
      <div className="donateform__finish--inner">
        <div className="finish-item">
          <div className="top">Сервер:</div>
          <div className="bottom">
            {server.label}
            <span className="icon-server"></span>
          </div>
        </div>

        <div className="finish-item">
          <div className="top">Логин:</div>
          <div className="bottom">
            {username}
            <span className="icon-login"></span>
          </div>
        </div>

        <div className="finish-item">
          <div className="top">Длительность:</div>
          <div className="bottom">
            {server.freeAddPrem > 1 ? (
              <div>
                <strike
                  style={{
                    marginRight: 5
                  }}
                >
                  {getPluralForm(parseInt(premiumId), ['день', 'дня', 'дней'])}
                </strike>

                {getPluralForm(Math.ceil(parseInt(premiumId) * server.freeAddPrem), [
                  'день',
                  'дня',
                  'дней'
                ])}
              </div>
            ) : (
              <div>{getPluralForm(parseInt(premiumId), ['день', 'дня', 'дней'])}</div>
            )}
            <span className="icon-money"></span>
          </div>
        </div>

        <div className="finish-item">
          <div className="top">К оплате:</div>
          <div className="bottom">
            {premiumAmount} ₽<span className="icon-money"></span>
          </div>
        </div>
      </div>

      {/* PGS Button */}
      <div>
        <form>
          {pgsLoading ? (
            <button className="paybutton cblue loading">Загружаем PGS</button>
          ) : (
            <a className="paybutton cblue" href={pgsData ? pgsData.paylink : ''}>
              Карты мира, скины, крипта <span>(PGS)</span>
            </a>
          )}
        </form>
      </div>

      {/* GMPays Button */}
      <div>
        <form>
          {gmpaysLoading ? (
            <button className="paybutton corange loading">Загружаем GMPays</button>
          ) : (
            <a className="paybutton corange" href={gmpaysData ? gmpaysData.paylink : ''}>
              СБП, Карты, Весь мир <span>(GMPays)</span>
            </a>
          )}
        </form>
      </div>

      {/* Freekasa Button */}
      <div>
        <form>
          {freekassaLoading ? (
            <button className="paybutton cred loading">Загружаем KASSA</button>
          ) : (
            <a className="paybutton cred" href={freekassaData ? freekassaData.url : ''}>
              Карты РФ, скины, крипта <span>(KASSA)</span>
            </a>
          )}
        </form>
      </div>

      {pgsFail && (
        <div className="errorbar">
          <p>Ошибка запроса к PGS</p>
        </div>
      )}

      {freekassaFail && (
        <div className="errorbar">
          <p>Ошибка запроса к KASSA</p>
        </div>
      )}

      {gmpaysFail && (
        <div className="errorbar">
          <p>Ошибка запроса к GMPays</p>
        </div>
      )}

      <p className="acceptpolicy">Я делаю добровольное пожертвование</p>
      <span className="prevbtn" onClick={() => prevStep()}>
        Назад
      </span>
    </div>
  )
}
